import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const ObeliskJakubowPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Jakubów, obelisk" />
      <ReturnToPrevPage to={'/gminy/jakubow'}>
        Materiały dotyczące gminy Jakubów
      </ReturnToPrevPage>
      <Header1>Jakubów, obelisk</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Obelisk upamiętniający bohaterów poległych w walkach o niepodległość
          Ojczyzny w latach 1919-1920 został odsłonięty w 1922 roku, a więc w
          czwartą rocznicę odzyskania Niepodległości. W okresie PRL na tym
          pomniku umieszczona była tablica poświęcona bohaterom poległym za
          wolność Ojczyzny w latach 1918-1945. Pomnikiem opiekuje się Szkoła im.
          Orła Białego w Jakubowie.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/jakubow/obelisk/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/jakubow/obelisk/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ObeliskJakubowPage
